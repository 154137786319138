<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Show all comments</h3>
	
	<p>To view all comments that you’ve made for a framework, as well as comments made by other users in any <span v-html="link('comment_groups', 'comment groups')"></span> you may be a member of:</p>
	
	<ul>
		<li>First <span v-html="link('signing_in', 'sign in')"></span> to <span v-html="app_name"/>, if you haven’t already.</li>
		<li><img srcset="/docimages/add_comments-1.png 3x" class="k-help-img float-right">Then turn on “comment mode” by clicking the <v-icon small>fas fa-comment</v-icon> icon in the upper-right corner of the framework. (If the icon has a white circle around it, comment mode is already on.)</li>
		<li><img srcset="/docimages/show_all_comments-2.png 3x" class="k-help-img float-right">With comment mode on, when you click on any framework item from the <span v-html="link('treeview', 'tree view')"></span> to view the item <span v-html="link('item_card', 'card')"></span>, you will see a <nobr><v-icon small>fas fa-comment</v-icon> SHOW ALL</nobr> button on the right side of the card. Click this button to open a table showing all comments for the framework:</li>
	</ul>
	<img srcset="/docimages/show_all_comments-1.png 2x" class="k-help-img block">
	<p>The top row of controls provides sorting and filtering options:</p>
	<ul>
		<li><b>Sort By</b>:<ul>
			<li>Item: showing comments for standards higher in the framework tree above comments for standards lower in the tree</li>
			<li>Date: showing more-recently posted comments and replies above older comments</li>
		</ul></li>
		<li><b>Comment Group</b>:<ul>
			<li>ALL GROUPS: show comments from all <span v-html="link('comment_groups', 'comment groups')"></span> you are a member of, as well as peronal comments</li>
			<li>Personal Comments: filter to show personal comments only</li>
			<li>Group names: filter to show comments from any one group you are a member of</li>
		</ul></li>
		<li><b>Comment Author</b>:<ul>
			<li>ALL AUTHORS: show comments by all authors (including yourself)</li>
			<li>Author names: filter to show comments from any one author</li>
		</ul></li>
		<li><b>Suggested Edits</b>:<ul>
			<li>ALL: show all comments</li>
			<li>NO SUGGESTED EDITS: filter to show only comments that don’t include suggested edits</li>
			<li>UNAPPLIED: filter to show comments with suggested edits that have NOT been applied</li>
			<li>APPLIED: filter to show comments with suggested edits that HAVE been applied</li>
		</ul></li>
		<li><b>Search</b>: Enter key words in the search box to filter to show comments whose comment text includes the key words, or whose item statement includes the key words</li>
	</ul>
	<p>Note that you can:</p>
		<ul>
			<li><img srcset="/docimages/add_comments-6.png 2x" class="k-help-img float-right">Hover over any comment in the table to reveal the “Edit” button (for comments you’ve written) and “Reply” button, and edit/reply to comments right in the table.</li>
			<li>Click on any item statement to open and view that statement in the framework tree.</li>
			<li>Click the <v-icon small class="mr-2">fas fa-users</v-icon>MANAGE COMMENT GROUPS button at the top of the table to create and administer <span v-html="link('comment_groups', 'comment groups')"></span>.</li>
		</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	